import { graphql, Link } from "gatsby"
import moment from "moment"
import React, { useEffect, useState } from "react"
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share"
import Blockquote from "~/components/flexible/Blockquote"
import Carousel from "~/components/flexible/Carousel"
import RelatedPost from "~/components/flexible/RelatedPost"
import TextBlock from "~/components/flexible/TextBlock"
import Image from "~/components/Image"
import Layout from "~/components/Layout"
import EventHero from "~/components/resources/EventHero"
import Facebook from "~/components/resources/share/Facebook"
import LinkedIn from "~/components/resources/share/LinkedIn"
import Twitter from "~/components/resources/share/Twitter"
import VideoHero from "~/components/resources/VideoHero"
import WhitePaperHero from "~/components/resources/WhitepaperHero"
import Seo from "~/components/Seo"
import VideoModal from "~/components/VideoModal"
import BlogPlay from "~/images/blog-play-icon.svg"

function BlogPost({ data: { wpPost } }) {
  // console.log(wpPost)

  const { blogPost, categories, flexibleContent, title, date, content } = wpPost

  // console.log(blogPost)
  return (
    <Layout headerBg="bg-transparent" dark={true} noise={false}>
      <Seo {...wpPost?.seo} />

      {blogPost?.blogPost?.heroType === "event" ? (
        <EventHero {...blogPost?.blogPost?.eventHero} date={date} />
      ) : blogPost?.blogPost?.heroType === "video" ? (
        <VideoHero
          date={date}
          category={categories?.nodes[0]?.name}
          {...blogPost?.blogPost?.videoHero}
        />
      ) : blogPost?.blogPost?.heroType === "whitepaper" ? (
        <WhitePaperHero
          date={date}
          category={categories?.nodes[0]?.name}
          {...blogPost?.blogPost?.whitepaperHero}
        />
      ) : blogPost?.blogPost?.blogHero?.heading &&
        blogPost?.blogPost?.blogHero?.image ? (
        <section className="relative h-full pt-16 md:pt-28 bg-slate">
          <div className="w-full h-full">
            <div className="md:max-w-[66.7%] 2xl:max-w-[56.7%] relative h-full w-full  ">
              <Image
                className="z-0 w-full h-full"
                loading="eager"
                objectFit="cover"
                image={blogPost?.blogPost?.blogHero?.image}
              />
            </div>
          </div>

          <div className="bottom-0 left-0 z-0 w-full md:absolute ">
            <div className="z-10 flex items-end justify-end -mt-20 md:container md:mt-0">
              <div className=" bg-black max-w-[43.125rem] w-full h-full z-10">
                <div className="pt-12 pl-5 md:pt-20 md:pl-16 pb-14 pr-9 ">
                  <div className="flex text-10 text-white uppercase opacity-70 tracking-[0.05em] leading-[1.109] mb-7">
                    <div className="mr-8">{categories?.nodes[0]?.name}</div>
                    <div>{moment(date).format("MMMM DD, YYYY")}</div>
                  </div>
                  <h1 className="text-white u-h3">
                    {blogPost?.blogPost?.blogHero?.heading}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="relative pt-32 pb-10 md:pt-40 lg:pt-56 md:pb-16 bg-slate">
          <div className="container z-0">
            <div className="flex justify-center">
              <div className="max-w-[47.813rem]">
                <div className="flex text-10 text-white uppercase opacity-70 tracking-[0.05em] leading-[1.109] mb-7 justify-center">
                  <div className="mr-8">{categories?.nodes[0]?.name}</div>
                  <div>{moment(date).format("MMMM DD, YYYY")}</div>
                </div>{" "}
                <h1 className="text-center text-white u-h3">{title}</h1>
              </div>
            </div>
          </div>
        </section>
      )}

      <section className="relative py-12 md:py-24 bg-light-grey">
        <div className="container relative">
          <div className="mx-auto max-w-[49.5rem]">
            {content ? (
              <div
                className="prose"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            ) : (
              flexibleContent?.flexibleContent?.map((section, i) => (
                <React.Fragment key={`${section.__typename}-${i}`}>
                  <RenderFlexibleSection section={section} />
                </React.Fragment>
              ))
            )}

            <div className="w-full h-px mt-16 bg-black opacity-20"></div>

            <div className="flex items-center justify-between mt-16">
              <Link to="/news" className="flex items-center ">
                <svg
                  width="13"
                  height="10"
                  viewBox="0 0 13 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.7485 5.48535L1.70901 5.48535L1.70901 4.0611L12.7485 4.0611L12.7485 5.48535Z"
                    fill="black"
                  />
                  <path
                    d="M4.77146 0.000459758L5.76904 0.998036L1.99371 4.77269L5.76904 8.54734L4.77147 9.54492L-9.95993e-05 4.77267L4.77146 0.000459758Z"
                    fill="black"
                  />
                </svg>
                <div className="ml-1.5 text-14 leading-[0.988] tracking-[-0.005em] font-medium ">
                  Back to articles
                </div>
              </Link>

              <div className="flex items-center">
                <div className=" text-14 leading-[0.988] tracking-[-0.005em] font-medium mr-2 hidden md:block  ">
                  Share
                </div>

                <BlogShare />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const BlogShare = () => {
  const [shareURL, setShareURL] = useState(null)

  useEffect(() => {
    const url = typeof window !== "undefined" ? window.location.href : ""
    url && setShareURL(url)
  }, [])

  return (
    <div className="flex items-center space-x-2">
      <FacebookShareButton url={shareURL} className=" focus:outline-none">
        <Facebook />
      </FacebookShareButton>

      <TwitterShareButton url={shareURL} className=" focus:outline-none">
        <Twitter />
      </TwitterShareButton>

      <LinkedinShareButton url={shareURL} className=" focus:outline-none">
        {" "}
        <LinkedIn />
      </LinkedinShareButton>
    </div>
  )
}

export default BlogPost

const RenderFlexibleSection = ({ section }) => {
  switch (section.fieldGroupName) {
    case "post_Flexiblecontent_FlexibleContent_FlexibleVideoModal":
      return (
        <VideoModal
          blogPlay={BlogPlay}
          className="my-16"
          iconClass="h-10 md:h-auto"
          imgClass={`md:aspect-h-3`}
          {...section}
        />
      )
    case "post_Flexiblecontent_FlexibleContent_FlexibleTextBlock":
      return <TextBlock {...section} />
    case "post_Flexiblecontent_FlexibleContent_FlexibleCarousel":
      return <Carousel {...section} />
    case "post_Flexiblecontent_FlexibleContent_FlexibleBlockquote":
      return <Blockquote {...section} />
    case "post_Flexiblecontent_FlexibleContent_FlexibleRelatedPost":
      return <RelatedPost {...section} />
    case "post_Flexiblecontent_FlexibleContent_FlexibleImage":
      return <Image className="my-16" {...section} />

    default:
      return null
  }
}

export const query = graphql`
  query blogPost($id: String!) {
    wpPost(id: { eq: $id }) {
      id
      title
      content
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      blogPost {
        blogPost {
          heroType
          blogHero {
            heading
            image {
              id
              altText
              localFile {
                url
                publicURL
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, quality: 100)
                }
              }
            }
          }
          eventHero {
            heading
            date
            content
            hostedBy {
              title
              name
              image {
                ...Image
              }
            }
            image {
              id
              altText
              localFile {
                url
                publicURL
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, quality: 100)
                }
              }
            }
          }
          videoHero {
            heading
            video {
              channel
              videoEmbed
              previewImage {
                id
                altText
                localFile {
                  url
                  publicURL
                  childImageSharp {
                    gatsbyImageData(placeholder: NONE, quality: 100)
                  }
                }
              }
            }
          }
          whitepaperHero {
            heading
            downloadFile {
              localFile {
                publicURL
              }
            }
            image {
              id
              altText
              localFile {
                url
                publicURL
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, quality: 100)
                }
              }
            }
          }
        }
      }
      date
      categories {
        nodes {
          name
        }
      }
      flexibleContent {
        flexibleContent {
          ... on WpPost_Flexiblecontent_FlexibleContent_FlexibleTextBlock {
            content
            fieldGroupName
          }
          ... on WpPost_Flexiblecontent_FlexibleContent_FlexibleImage {
            fieldGroupName
            image {
              ...Image
            }
          }
          ... on WpPost_Flexiblecontent_FlexibleContent_FlexibleVideoModal {
            fieldGroupName
            videoEmbed
            previewImage {
              ...Image
            }
          }
          ... on WpPost_Flexiblecontent_FlexibleContent_FlexibleCarousel {
            fieldGroupName
            images {
              image {
                ...Image
              }
              caption
            }
          }
          ... on WpPost_Flexiblecontent_FlexibleContent_FlexibleBlockquote {
            fieldGroupName
            quote
            name
            title
          }
          ... on WpPost_Flexiblecontent_FlexibleContent_FlexibleRelatedPost {
            fieldGroupName
            heading
            post {
              ... on WpPost {
                id
                uri
                title
                featuredImage {
                  node {
                    ...Image
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
